body {
  background: url("../../public/static/img/bg.png");
}

a {
  text-decoration: none !important;
  color: #fff !important;
}

h1 {
  color: #fff !important;
}


.navigation {
  margin-top: 50px;
}

.playicon {
  width: 50px;
  margin-right: 20px;
}

.homeicon {
  width: 50px;
}

.circleBox {
  margin-top: 100px;
}

.circle {
  width: 500px !important;
  height: 500px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid #fff;
}

.button {
  position: absolute;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  border-radius: 50%; /* Makes it circular */
  background-color: #007bff; /* Button color */
  color: white; /* Button text color */
  border: none;
  cursor: pointer;
}

.button:nth-child(1) {
  width: 117px;
  height: 117px;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1b1e35;
  color: white;
  border: 2px solid #067ba4;
  border-radius: 64px;
}

.button:nth-child(2) {
  bottom: 30%;
  left: -6%;
  transform: translateY(50%);
  width: 117px;
  height: 117px;
  background-color: #1a3b15;
  color: white;
  border: 2px solid #4b8510;
  border-radius: 64px;
}

button:nth-child(3) {
  bottom: 30%;
  right: -10%;
  transform: translateY(50%);
  width: 117px;
  height: 117px;
  background-color: #1b1e35;
  color: white;
  border: 2px solid #6f6886;
  border-radius: 64px;
}

.button-container {
  display: flex;
  flex-direction: column; /* Arrange buttons vertically */
  align-items: center; /* Align buttons to the center horizontally */
}

.button-container .buttons {
  margin: 5px 0; /* Adjust margin between buttons */
  color: #fff;
  background-color: #0c4055;
  border: 2px solid #76b0be;
  padding: 10px;
  text-align: center;
}

.buttons:nth-child(1) {
  margin-bottom: 5px;
}

.vm {
  width: 150px;
  margin: 30px;
}

.tera {
  width: 70px;
}

.buttons:nth-child(2) {
  position: relative; /* Make button position relative */
}

.button-container .buttons:nth-child(2)::before,
.button-container .buttons:nth-child(2)::after {
  content: '';
  position: absolute;
  width: calc(100% - 5px); /* Set line width */
  border-top: 2px solid #fff; /* Line color */
  top: -6px; /* Position above the button */
  z-index: -1; /* Ensure lines are behind button text */
  transform: rotate(90deg);
  right: 0;
}

.button-container .buttons:nth-child(2)::after {
  top: 100%; /* Position below the button */
}


.headersection {
  text-align: center;
  position: relative;
}
.homeiconsec {
  position: absolute;
  right: 0;
  top: 0;
  width: 180px;
}