.navigationButtons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  
}

.button {
  padding: 6px 20px;
  background-color: rgba(0, 143, 189, 0.52);
  color: #ffffff;
  border: 1px solid #008FBD;
  border-radius: 0;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s;
  outline: none;
  height: 36px;
  width: 120px;
  margin-bottom: 10px;
}


.button:hover{
  background-color: rgba(0, 105, 140, 0.52);
  border: 1px solid #ffffff;
}

.button:disabled,
.button.disabled {
  background-color: #cccccc;
  color: #666666;
  border-color: #cccccc;
  cursor: not-allowed;
}

.button i,
.button span.icon {
  margin-right: 8px;
}