.screen {
    display: flex;
    flex-direction: column;
    background-color: #0a274800;
    color: #ffffff;
    font-family: 'Metropolis', sans-serif;
    height: 100vh;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
}

.videoContent {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.headerSection {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 6px 0;
    height: 143px;
    border-bottom: 1px solid #137ea5;
}

.developmentBadgeContainer {
    flex-basis: 20%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    /* padding-bottom: 10px; */
}

.developmentBadge {
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 300;
    padding: 5px 10px;
    font-size: .75em;
    margin-right: auto;
}

.textContent {
    flex-basis: 100%;
    text-align: center;
}

.title {
    font-family: Metropolis, sans-serif;
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.subtitle {
    font-family: Metropolis, sans-serif;
    font-weight: 300;
    margin: 0;
    text-transform: uppercase;
    min-height: 20px;
    padding: 0px 0;
    font-weight: 300;
}

.icons {
    flex-basis: 20%;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.icon {
    font-size: 1.5rem;
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
}

.mainContent {
    position: relative;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #137ea5;
}

.contentImage {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.h1{
    font-family: 'Metropolis', sans-serif;
}