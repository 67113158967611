/* index.css */
@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/Metropolis-ExtraLight.ttf') format('truetype');
  font-weight: 200; /* Extra Light */
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/Metropolis-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/Metropolis-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/Metropolis-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../public/fonts/Metropolis-SemiBold.ttf') format('truetype');
  font-weight: 600; /* Semi Bold */
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Metropolis', sans-serif; /* Using Metropolis as the main font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Metropolis', monospace; /* You can also specify the Metropolis font for code if you like */
}
