.screen {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #0A2748; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fullScreenContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  